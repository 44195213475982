# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
jQuery ->

  $(document).on 'click', '.select_image', (event) ->
    selected_img =  $("#picture_selected").find('option:selected')
    imgpath = selected_img.data('url')
    $("#swap").attr
      src: imgpath
    return

  $(document).on 'click', '.remove_fields', (event) ->
    $(this).prev('input[type=hidden]').val('1')
    $(this).closest('fieldset').hide()
    event.preventDefault()

  $(document).on 'click', '.add_fields', (event) ->
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    separator = $("#separator")
    newitem = $(this).data('fields').replace(regexp, time)
    newitem = newitem.replace('https://dummyimage.com/75x75/000/fff.png', $("#swap").attr('src'))
    separator.before(newitem)
    selimg = $("#picture_selected").find('option:selected')
    cur_page_name_id = $("#cur_page_name_id")
    $("#page_name_galleries_attributes_"+time+"_page_name_id").val(cur_page_name_id.val())
    $("#page_name_galleries_attributes_"+time+"_picture_id").val(selimg.val())
    $("#page_name_galleries_attributes_"+time+"_order").val('10')
    event.preventDefault()

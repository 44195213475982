# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
jQuery ->

  $(document).on 'shown.bs.modal', '.modal-initial-fit', (event) ->
    resizeModal($(this))
    

  $(document).on 'slid.bs.carousel', '.carousel.carousel-fit', (event) ->
    resizeModal($(this))

  resizeModal =(modalOrCarousel) ->
    innerCarousel = modalOrCarousel.find('.carousel-inner')
    activeItem = innerCarousel.find('.active')
    activeImage = activeItem.find('img')
    imageHeight = activeImage.height()
    imageWidth = activeImage.width()
    modalDialog = innerCarousel.parents('.modal-fit')
    windowHeight = $(window).height();
    windowWidth = $(window).width();
    if windowWidth < imageWidth
      activeImage.width(windowWidth - 25)
      activeImage.height('auto')
      modalDialog.height(windowHeight)
      modalDialog.width(windowWidth - 15)
    else
      modalDialog.height(imageHeight)
      modalDialog.width(imageWidth)